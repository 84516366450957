/* eslint-disable react/no-unknown-property */
// TODO Remove linter disable comment once the @three/fiber components like points or mesh
// will be updated to CamelCase or linter will be updated to also recognize lowerCase names
import * as React from "react";
import type { FallbackProps } from "react-error-boundary";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Sky } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";

import { UserProfileDto } from "@volley/data";
import { convert } from "@volley/physics";
import { localization2physics } from "@volley/physics/dist/conversions";
import { CourtPoint, PhysicsModelName } from "@volley/physics/dist/models";
import { TrainerSim } from "@volley/physics/dist/trainer-sim";

import logger from "../../../log";
import { fetchApi } from "../../../util";
import { usePhysicsModelContext } from "../../hooks/PhysicsModelProvider";
import { PositionProximity } from "../../hooks/usePosition";
import { Sport, useSelectedSport } from "../context/sport";

import BallTrack from "./BallTrack";
import CameraOrbitControls from "./CameraOribitControls";
import CameraViewButtons from "./CameraViewButtons";
import {
    Ball,
    Court3DModel,
    DrawArrow,
    SimpleCourt,
    PlayerModelMulti,
    RenderTrainerPosition,
    TrainerModelMulti,
    Ground,
    AOI,
    CourtAOI,
    YawDiff,
} from "./models";
import type {
    CameraView,
    CameraViewStatus,
    WorkoutForVisualizer,
} from "./types";
import { localization2visualizer } from "./utils";

interface RenderInfo {
    playerPositions: CourtPoint[];
    trainerPositions: RenderTrainerPosition[];
    AOIs: CourtAOI[];
}

export function fallbackRender({ resetErrorBoundary }: FallbackProps) {
    return (
        <Stack spacing={3}>
            <Typography variant="h3" textAlign="center" gutterBottom>
                Unable to Draw Graphic
            </Typography>
            <Button
                onClick={() => resetErrorBoundary()}
                variant="contained"
                color="secondary"
                fullWidth
            >
                Retry
            </Button>
        </Stack>
    );
}

function setDefaultPhysicsParams() {
    const pModel = convert.getPhysicsModel();
    pModel.simParams.bounces = 4;
    pModel.simParams.timeStep = 0.005;
    pModel.simParams.wallBounceEnabled = true;
    pModel.simParams.netBounceEnabled = false;
    pModel.constants.courtRestCoeff = 0.7;
    pModel.constants.wallRestCoeff = 0.3;
    pModel.ballParams.drag = 1.2;
}

function setTennisPhysicsParams() {
    const pModel = convert.getPhysicsModel();
    pModel.simParams.bounces = 2;
    pModel.simParams.timeStep = 0.005;
    pModel.simParams.wallBounceEnabled = false;
    pModel.simParams.netBounceEnabled = false;
    pModel.constants.courtRestCoeff = 0.7;
    pModel.ballParams.drag = 0.8;
}

function setPickleballParams() {
    const pModel = convert.getPhysicsModel();
    pModel.simParams.bounces = 2;
    pModel.simParams.timeStep = 0.005;
    pModel.simParams.wallBounceEnabled = false;
    pModel.simParams.netBounceEnabled = false;
    pModel.constants.courtRestCoeff = 0.7;
    pModel.ballParams.drag = 0.6;
}

export interface WorkoutVisualizerProps {
    workout?: WorkoutForVisualizer;
    positionProximity: PositionProximity;
    onLoad: () => void;
    width: number;
    height: number;
    renderCameraControls?: boolean;
    animationConfig?: {
        action: "play" | "pause" | "stop";
        playbackSpeed: number;
        onComplete: () => void;
        onProgressChange: (progress: { thrown: number; total: number }) => void;
    };
    sport?: Sport;
    physicsModel?: PhysicsModelName;
    improveMode?: "xy" | "yaw" | "both";
    enableCameraControl?: boolean;
}

export default function WorkoutVisualizer({
    workout,
    positionProximity,
    onLoad,
    width,
    height,
    renderCameraControls = true,
    animationConfig,
    sport: sportProp,
    physicsModel: physicsModelProp,
    improveMode,
    enableCameraControl = true,
}: WorkoutVisualizerProps): JSX.Element {
    const [cameraRequestedView, setCameraRequestedView] =
        React.useState<CameraView>("default");
    React.useEffect(() => {
        logger.info(`cameraRequestedView value: ${cameraRequestedView}`);
    }, [cameraRequestedView]);
    const [cameraViewStatus, setCameraViewStatus] =
        React.useState<CameraViewStatus>("reached");
    const [isReserve, setIsReserve] = React.useState(false);

    const { selected: contextSport } = useSelectedSport();
    const sport = sportProp ?? contextSport;
    const { physicsModelName } = usePhysicsModelContext();
    const physicsModel = physicsModelProp ?? physicsModelName;

    React.useEffect(() => {
        if (positionProximity === "Improve") {
            setCameraRequestedView("default");
        }
    }, [positionProximity]);

    // TODO Review the reserve effects once we will have solid way of customizing textures
    React.useEffect(() => {
        fetchApi<UserProfileDto>("/api/users/me")
            .then(({ profile }) => {
                setIsReserve(
                    (profile?.homeLocation?.name
                        .toLowerCase()
                        .indexOf("reserve") ?? -1) !== -1,
                );
            })
            .catch((e) =>
                logger.error(
                    `Failed to fetch user's home location, Error: ${JSON.stringify(e)}`,
                ),
            );
    }, []);

    // Update trainer simulator and physics model for selected sport
    const sim = React.useMemo(() => {
        if (sport === "TENNIS") {
            setTennisPhysicsParams();
        } else if (sport === "PICKLEBALL") {
            setPickleballParams();
        } else {
            setDefaultPhysicsParams();
        }
        return new TrainerSim(physicsModel as PhysicsModelName, sport);
    }, [physicsModel, sport]);
    const simConfig = React.useMemo(() => sim.GetConfig(), [sim]);

    const renderInfo: RenderInfo = React.useMemo(() => {
        if (!workout) {
            return {
                playerPositions: [],
                trainerPositions: [],
                AOIs: [],
            };
        }

        const headHeightIn = workout.trainer.heightIn;
        const headHeightMeters = convert.launchHeight2HeadHeight(
            (headHeightIn * 25.4) / 1000,
        );

        let trainerPositionPhysics = { ...workout.trainer };
        if (sport === "PLATFORM_TENNIS") {
            const converted = convert.localization2physics({
                x: workout.trainer.x ?? 0,
                y: workout.trainer.y ?? 0,
                z: headHeightMeters * 1000,
            });
            trainerPositionPhysics = {
                ...trainerPositionPhysics,
                x: converted.x,
                y: converted.y,
            };
        }

        let trainerPosition: RenderTrainerPosition = {
            x: trainerPositionPhysics.x,
            y: trainerPositionPhysics.y,
            yaw: workout.trainer.yaw,
            h: headHeightMeters,
            render: "FULL",
            launchPosition: { x: 0, y: 0, z: 0 },
            armAngle: 0,
        };

        const playerPositions = workout.player.map((p) => {
            if (sport === "PLATFORM_TENNIS") {
                return localization2physics({
                    x: p.x,
                    y: p.y,
                    z: 0,
                });
            }
            return {
                x: p.x,
                y: p.y,
                z: 0,
            };
        });

        const AOIs = workout.AOIs ? workout.AOIs.map((a) => a) : [];

        let localizedPosition: RenderTrainerPosition | undefined;
        if (workout.localized) {
            const localizedHeightMeters = convert.launchHeight2HeadHeight(
                (workout.localized.heightIn * 25.4) / 1000,
            );
            const localizedPositionPhysics = convert.localization2physics({
                x: workout.localized.x,
                y: workout.localized.y,
                z: localizedHeightMeters * 1000,
            });

            localizedPosition = {
                armAngle: 0,
                h: localizedHeightMeters,
                launchPosition: { x: 0, y: 0, z: 0 },
                render: "FULL",
                x: localizedPositionPhysics.x,
                y: localizedPositionPhysics.y,
                // NOTE: Angles (roll, pitch, yaw) returned from vision `/position` endpoint are in radians
                yaw: workout.localized.yaw,
            };
            // When we are localized & could improve the actual trainer position turns to ghost
            if (positionProximity === "Improve") {
                trainerPosition.render = "GHOST";
                // Otherwise we will use the localized position as good trainerPosition to reset camera positions
            } else {
                trainerPosition = localizedPosition;
            }
        } else if (positionProximity === "Unsure") {
            trainerPosition.render = "GHOST";
        }

        // NOTE: Because we want to draw the shots from the localizedPosition we simulate the trainerPosition first
        // sim.SetPositionManual() expects yaw angles to be in degrees
        sim.SetPositionManual({
            ...trainerPosition,
            yaw: THREE.MathUtils.radToDeg(trainerPosition.yaw),
        });
        trainerPosition.launchPosition = sim.GetLaunchPoint();
        trainerPosition.armAngle = THREE.MathUtils.degToRad(sim.GetArmAngle());

        if (localizedPosition) {
            sim.SetPositionManual({
                ...localizedPosition,
                yaw: THREE.MathUtils.radToDeg(localizedPosition.yaw),
            });
            localizedPosition.launchPosition = sim.GetLaunchPoint();
            localizedPosition.armAngle = THREE.MathUtils.degToRad(
                sim.GetArmAngle(),
            );
        }

        const trainerPositions =
            localizedPosition && positionProximity === "Improve"
                ? [localizedPosition, trainerPosition]
                : [trainerPosition];

        const result = {
            playerPositions,
            trainerPositions,
            AOIs,
        };

        return result;
    }, [workout, sport, sim, positionProximity]);

    React.useEffect(() => {
        setCameraViewStatus("requested");
    }, [cameraRequestedView, renderInfo.trainerPositions]);

    return (
        <Box component="div">
            <Canvas
                onCreated={() => onLoad()}
                gl={(canvas) =>
                    new THREE.WebGLRenderer({
                        canvas,
                        antialias: true,
                        alpha: true,
                        powerPreference: "high-performance",
                    })
                }
                camera={{
                    visible: false,
                    fov: 55,
                    near: 0.1,
                    far: 1000,
                }}
                id="webglCanvas"
                style={{
                    position: "absolute",
                    width,
                    height,
                    display: "block",
                    margin: "auto",
                    border: "1px solid #d3d3d3",
                    background: "darkgrey",
                }}
            >
                {positionProximity !== "Improve" &&
                    renderInfo.trainerPositions.length && (
                        <BallTrack
                            trainerPosition={renderInfo.trainerPositions[0]}
                            shots={workout?.shots ?? []}
                            sim={sim}
                            animationConfig={animationConfig}
                        />
                    )}
                {renderInfo.trainerPositions.length > 0 && (
                    <Ball
                        scale={0.08}
                        position={localization2visualizer(
                            renderInfo.trainerPositions[0].launchPosition,
                        )}
                        color="yellow"
                    />
                )}
                <React.Suspense
                    fallback={
                        <SimpleCourt sport={sport} isReserve={isReserve} />
                    }
                >
                    <Court3DModel sport={sport} isReserve={isReserve} />
                </React.Suspense>
                <React.Suspense>
                    {renderInfo.playerPositions.map((p) => (
                        <PlayerModelMulti
                            position={localization2visualizer(p)}
                            rotation={
                                new THREE.Euler(-Math.PI / 2, 0, -Math.PI)
                            }
                            scale={1}
                            key={`${p.x}-${p.y}`}
                        />
                    ))}
                </React.Suspense>
                <React.Suspense>
                    {renderInfo.trainerPositions.map((t) => (
                        <TrainerModelMulti
                            key={t.render}
                            position={new THREE.Vector3(t.x, t.y, 0)}
                            rotation={new THREE.Euler(-Math.PI / 2, 0, -t.yaw)}
                            simConfig={simConfig}
                            armAngle={t.armAngle}
                            launchPosition={
                                new THREE.Vector3(
                                    t.launchPosition.x,
                                    t.launchPosition.y,
                                    t.launchPosition.z,
                                )
                            }
                            scale={1.0}
                            render={t.render}
                        />
                    ))}
                </React.Suspense>
                {renderInfo.AOIs.map((a) => (
                    <AOI
                        size={a.size}
                        position={
                            new THREE.Vector3(
                                a.position.x,
                                a.position.y,
                                a.position.z,
                            )
                        }
                        rotation={new THREE.Euler(-Math.PI / 2, 0, 0)}
                        color={a.color}
                        opacity={a.opacity}
                        key={`${a.position.x}-${a.position.y}`}
                    />
                ))}
                {renderInfo.trainerPositions.length === 2 &&
                    (improveMode === "xy" || improveMode === "both") && (
                        <DrawArrow
                            trainerPositions={renderInfo.trainerPositions}
                        />
                    )}
                {renderInfo.trainerPositions.length === 2 &&
                    (improveMode === "yaw" || improveMode === "both") && (
                        <YawDiff
                            actualYaw={renderInfo.trainerPositions[0].yaw}
                            expectedYaw={renderInfo.trainerPositions[1].yaw}
                            position={
                                new THREE.Vector3(
                                    renderInfo.trainerPositions[1].x,
                                    renderInfo.trainerPositions[1].y,
                                    0,
                                )
                            }
                        />
                    )}
                <ambientLight color="white" intensity={Math.PI} />
                <CameraOrbitControls
                    cameraViewStatus={cameraViewStatus}
                    requestedView={cameraRequestedView}
                    setCameraViewStatus={setCameraViewStatus}
                    trainerPositions={renderInfo.trainerPositions}
                    enableCameraControl={enableCameraControl}
                />
                <Sky distance={400000} sunPosition={[1, 1, 1]} />
                <Ground />
            </Canvas>
            {renderCameraControls && (
                <CameraViewButtons
                    cameraRequestedView={cameraRequestedView}
                    setCameraRequestedView={setCameraRequestedView}
                    cameraViewStatus={cameraViewStatus}
                    setCameraViewStatus={setCameraViewStatus}
                />
            )}
        </Box>
    );
}
