import * as React from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";

import ChecklistIcon from "@mui/icons-material/Checklist";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";

import type {
    OffsetResult,
    AppWorkoutListItem,
    TagCategoryWithRelations,
    Tag,
} from "@volley/data";

import logger from "../log";
import userMonitor from "../userMonitor";
import fetchApi, { logFetchError } from "../util/fetchApi";

import FeaturedItems from "./Home/FeaturedItems";
import {
    FetchCriteria,
    HomeScreenCategoryDefinition,
    categories,
    type HomeScreenCategory,
} from "./HomeScreen";
import HomeScreenRow from "./HomeScreenRow";
import PTIHomeRow from "./PTI/HomeRow";
import FilterState from "./Trainer/AppWorkouts/apps/shared/filterState";
import Loading from "./common/Loading";
import { useSelectedSport } from "./common/context/sport";
import { useCurrentUser } from "./hooks/currentUser";
import { useStatus } from "./hooks/status";

const INITIAL_FETCH = 500;

type Action =
    | { type: "setLoading"; value: "init" | "fetching" | "done" }
    | { type: "addCategories"; value: HomeScreenCategory[] }
    | { type: "setTags"; value: Record<number, Tag[]> };

interface HomeScreenState {
    loading: "init" | "fetching" | "done";
    categories: HomeScreenCategory[];
}

function homeScreenReducer(
    state: HomeScreenState,
    action: Action,
): HomeScreenState {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.value,
                categories: action.value === "init" ? [] : state.categories,
            };
        case "addCategories":
            return {
                ...state,
                categories: action.value,
            };
        default:
            return state;
    }
}

const initialState: HomeScreenState = {
    loading: "init",
    categories: [],
};

function buildTagFetchUrl(
    tagId: number,
    sport: string,
    limit: number,
    offset: number,
): string {
    // for the home screen, we want to sort based on how recently an item has been tagged
    const baseUrl =
        "/api/app-workouts/search?sortField=wt.updated_at&sortDirection=desc&user=false";
    const url = `${baseUrl}&tagIds=${tagId}&sport=${sport}&limit=${limit}&offset=${offset}`;
    return url;
}

export default function HorizontalScrollHome(): JSX.Element {
    const statusValue = useStatus();
    const navigate = useNavigate();
    const { selected: sport } = useSelectedSport();
    const [state, dispatch] = React.useReducer(homeScreenReducer, initialState);
    const { features } = useCurrentUser();

    React.useEffect(() => {
        dispatch({ type: "setLoading", value: "init" });
    }, [sport]);

    React.useEffect(() => {
        async function fetchData() {
            const homeScreenCategories = await fetchApi<
                TagCategoryWithRelations[]
            >("/api/tags/list?onlyHomeScreen=true");
            const fetchCriteria: FetchCriteria = {
                limit: INITIAL_FETCH,
                offset: 0,
                sport,
                trainerId: statusValue.status?.clientId,
            };
            const tagCategories = homeScreenCategories.flatMap((c) =>
                c.tags.map((t, i) => {
                    const category: HomeScreenCategoryDefinition = {
                        sports: [
                            "PADEL",
                            "PICKLEBALL",
                            "PLATFORM_TENNIS",
                            "TENNIS",
                        ],
                        fetchResults: async ({
                            sport: selectedSport,
                            limit,
                            offset,
                        }: FetchCriteria) => {
                            const results = await fetchApi<
                                OffsetResult<AppWorkoutListItem>
                            >(
                                buildTagFetchUrl(
                                    t.id,
                                    selectedSport,
                                    limit,
                                    offset,
                                ),
                            );
                            return {
                                name: t.name,
                                label: t.label,
                                order: i,
                                results,
                            };
                        },
                    };
                    return category;
                }),
            );
            const copy = [
                ...tagCategories,
                ...categories.filter((c) => c.sports.includes(sport)),
            ];
            const allResults = await Promise.allSettled(
                copy.map((c) => c.fetchResults(fetchCriteria)),
            );
            let homeClubId: number | null = null;
            const categoriesToAdd = allResults
                .filter((r) => r.status === "fulfilled")
                .sort((a, b) => a.value.order - b.value.order)
                .reduce<HomeScreenCategory[]>((acc, result) => {
                    const { name, results, showIfEmpty } = result.value;
                    if (results.count || showIfEmpty) {
                        if (
                            name === "trainer_club" &&
                            homeClubId !== null &&
                            results.result[0].provider?.id === homeClubId
                        ) {
                            return acc; // skip adding the trainer's club if it is the same as the home club
                        }

                        acc.push(result.value);

                        if (name === "home_club") {
                            homeClubId = results.result[0].provider?.id ?? null;
                        }
                    }
                    return acc;
                }, []);
            dispatch({
                type: "addCategories",
                value: categoriesToAdd,
            });
        }

        if (state.loading === "init") {
            dispatch({ type: "setLoading", value: "fetching" });
            fetchData()
                .catch((e) => {
                    logFetchError(e);
                })
                .finally(() => dispatch({ type: "setLoading", value: "done" }));
        }
    }, [sport, state.loading, statusValue.status?.clientId]);

    // HACK: This single-pixel scroll forces the iOS Webview to correctly
    // render the home screen (instead of a large, blank white box) when
    // navigating back to the home page after clicking one of the tiles.
    // This rendering bug is only present on the iOS Webview and hasn't been
    // seen in other user agents.
    React.useEffect(() => {
        const isIOSWebview = /(iPhone|iPad).*AppleWebKit(?!.*Safari)/i.test(
            navigator.userAgent,
        );
        if (state.loading === "done" && isIOSWebview) {
            logger.debug("Executing scroll for iOS webview render glitch");
            requestAnimationFrame(() => {
                window.scrollTo(0, 1);
                window.scrollTo(0, 0);
            });
        }
    }, [state.loading]);

    const scrollTrackingRef = React.useRef(true);
    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                userMonitor.addAction("vertical-scroll", {});
                window.removeEventListener("scroll", handleScroll);
            }
        };

        if (scrollTrackingRef.current) {
            scrollTrackingRef.current = false;
            window.addEventListener("scroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    if (state.loading !== "done") {
        return <Loading />;
    }

    const featured = state.categories.find((c) => c.name === "FEATURED");

    return (
        <Stack
            spacing={2}
            sx={{
                position: "relative",
                overscrollBehavior: "contain",
                paddingBottom: "100px",
                backgroundColor: (t) => t.palette.primary.dark,
                maskImage:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)",
            }}
        >
            {featured && <FeaturedItems category={featured} />}
            {sport === "PLATFORM_TENNIS" && features.includes("BASIC_MODE") && (
                <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    startIcon={<ChecklistIcon />}
                    component={RouterLink}
                    to="/content/apps/workouts/experimental/basic/play?tag=BASIC_BACKCOURT"
                >
                    Quick Start - Back Court
                </Button>
            )}
            {state.categories
                .filter((c) => c.name !== "FEATURED")
                .filter(
                    (c) =>
                        c.requiredFlag === undefined ||
                        features.includes(c.requiredFlag),
                )
                .map((c) => (
                    <HomeScreenRow key={c.name} category={c} />
                ))}
            {sport === "PLATFORM_TENNIS" && <PTIHomeRow />}
            <Box
                sx={{
                    position: "fixed",
                    bottom: "40px",
                    right: { xs: "20px", sm: "300px" },
                }}
            >
                <Fab
                    size="large"
                    color="secondary"
                    onClick={() => {
                        FilterState.clearFilter();
                        navigate("/content/apps/workouts/search");
                    }}
                >
                    <SearchIcon />
                </Fab>
            </Box>
        </Stack>
    );
}
